import React from 'react';
import NavBar from '../components/NavBar';
import CallToAction from '../components/CallToAction';
import BoltChip from '../components/BoltChip';
import DesignChip from '../components/DesignChip';
import RedoChip from '../components/RedoChip';
import {Image} from "@nextui-org/react";
import OldHouse from '../assets/images/oldHouse.webp';
import NewHouse from '../assets/images/modernHouse.webp';
import Footer from '../components/Footer';

import '../styles/Home.css';

function Home() {
    return(
        <body>
            <NavBar></NavBar>
            <section className='welcomeSection'>
                <h1 className='welcomeTitle'>Casual Entertainment</h1>
                <h2 className='subTitle'>La reforma de tus sueños comienza aquí.</h2>
                <CallToAction></CallToAction>
            </section>
            <section className='dedicationSection'>
                <h2 className='sectionTitle'>¿A qué nos dedicamos?</h2>
                <p className='sectionDescription'>Nos especializamos en el diseño y la reforma del hogar, transformando espacios en obras maestras personalizadas que reflejan tu estilo único. Con un enfoque meticuloso y un equipo de expertos apasionados, creamos ambientes que no solo cumplen con tus expectativas, sino que también despiertan emociones y elevan tu experiencia de vida diaria.</p>
                <div className='chipsSection'>
                    <DesignChip></DesignChip>
                    <RedoChip></RedoChip>
                    <BoltChip></BoltChip>
                </div>
            </section>
            <section className='differencesSection'>
                <h2 className='sectionTitle'>Marcamos la diferencia.</h2>
                <div className='differencesImages'>
                    <div className='leftSide'>
                        <h3 className='imageTitle'>Antes</h3>
                        <Image width={600} alt='Casa antes de su reforma.' src={OldHouse}></Image>
                    </div>
                    <div className='rightSide'>
                        <h3 className='imageTitle'>Después</h3>
                        <Image width={600} alt='Casa después de su reforma.' src={NewHouse}></Image>
                    </div>
                </div>
            </section>
            <Footer></Footer>
        </body>
    );
}

export default Home;