import React from 'react';
import HelmetIcon from '../assets/icons/helmet.svg';

import '../styles/RedoChip.css';

function RedoChip() {
    return(
        <div className='redoChipContainer'>
            <div className='iconContainer'>
                <img src={HelmetIcon} alt="Un casco de obra" className='chipIcon' />
            </div>
            <p className='chipName'>Reforma</p>
        </div>
    )
}

export default RedoChip;