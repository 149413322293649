import React from 'react';

import '../styles/CallToAction.css';

function CallToAction() {
    return(
        <div className='buttonContainer'>
            <a href="/contact" className='buttonText'>Quiero saber más</a>
        </div>
    )
}

export default CallToAction;