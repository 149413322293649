import React from 'react';
import DesignIcon from '../assets/icons/design.svg';

import '../styles/DesignChip.css';

function DesignChip() {
    return(
        <div className='chipContainer'>
            <div className='iconContainer'>
                <img src={DesignIcon} alt="Un lapiz y una regla" className='chipIcon' />
            </div>
            <p className='chipName'>Diseño</p>
        </div>
    )
}

export default DesignChip;