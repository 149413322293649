import React from 'react';
import DesignIcon from '../assets/icons/bolt.svg';

import '../styles/BoltChip.css';

function BoltChip() {
    return(
        <div className='boltChipContainer'>
            <div className='iconContainer'>
                <img src={DesignIcon} alt="Un rayo indicando electricidad" className='chipIcon' />
            </div>
            <p className='chipName'>Instalaciones</p>
        </div>
    )
}

export default BoltChip;