import React from 'react';
import FooterLogo from '../assets/images/miniWhite.svg';


import '../styles/Footer.css';

function Footer() {
    return(
        <section className='footer'>
            <div className='footerContainer'>
                <div className='brandedSection'>
                    <img src={FooterLogo} alt="Logo of" className='footerLogo'/>
                    <p className='copyright'>© 2024 Casual Entertainment. Todos los derechos reservados.</p>
                </div>
                <div className='faqSection'>
                    
                </div>
                <div className='linksSection'></div>
            </div>
        </section>
    )
}

export default Footer;