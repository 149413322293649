import React from 'react';
import NavBar from '../components/NavBar';
import PlanningImage from '../assets/images/whoCharacter.png';
import PartnerImage from '../assets/images/collabCharacter.png';
import Footer from '../components/Footer';
import '../styles/About.css';

function About() {
    return(
        <body className='aboutBody'>
            <NavBar></NavBar>
            <section className='whoWeAreSection'>
                <h2 className='sectionTitle'>¿Quiénes somos?</h2>
                <p className='sectionDescription'>En Casual Entertainment nos dedicamos con pasión a la reforma de hogares, convirtiendo sueños en realidades y reflejando la esencia única de cada cliente en cada rincón de su hogar.</p>
                <p className='sectionDescription'>Somos expertos en llevar a cabo transformaciones que van más allá de las expectativas, destacándonos en el mundo de la reforma de hogares gracias a nuestro enfoque meticuloso y nuestra devoción por el diseño excepcional. En cada proyecto, nos esforzamos por capturar la visión de nuestros clientes y plasmarla en un hogar distinguido y personalizado.</p>
                <img className='sectionImage' src={PlanningImage} alt="" />
            </section>
            <section className='characterSection'>
                <h2 className='sectionTitle'>Tu eres el protagonista.</h2>
                <p className='sectionDescription'>Entendemos que cada persona es el verdadero protagonista de su historia y, por ende, de la transformación de su hogar. Nos enorgullece guiar a nuestros clientes a lo largo de todo el proceso, desde la concepción de ideas hasta la elección de elementos y materiales que definirán su propio estilo y personalidad.</p>
                <p className='sectionDescription'>Nuestra filosofía se basa en tres pilares fundamentales: calidad, precio y compromiso. Buscamos siempre la excelencia en cada detalle, ofreciendo soluciones de alta calidad que se ajusten a diversos presupuestos. Nos comprometemos a brindar un servicio transparente, enfocado en la satisfacción del cliente y en la creación de hogares que superen todas las expectativas.</p>
                <img className='sectionImage' src={PartnerImage} alt="" />
            </section>
            <section className='experienceSection'>
                <h2 className='sectionTitle'>Todo una experiencia.</h2>
                <p className='sectionDescription'>No solo transformamos espacios, sino que también creamos experiencias inolvidables. Permítanos ser su guía en el emocionante viaje de transformar su hogar en un lugar extraordinario que refleje su estilo y personalidad. Descubra la diferencia que hace Transforma Hogar en el arte de la reforma de hogares. Descubre la diferencia de trabajar con Casual Entertainment.</p>
            </section>
            <Footer></Footer>
        </body>
    )
}

export default About;