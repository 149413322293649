import React from 'react';
import logo from './logo.svg';
import './App.css';
import {NextUIProvider} from "@nextui-org/react";
import AppRouter from './router/AppRouter';

function App() {
  return (
    <NextUIProvider>
      <AppRouter></AppRouter>
    </NextUIProvider>
  );
}

export default App;
