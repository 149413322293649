import React from 'react';
import Logo from '../logo.svg';

import '../styles/NavBar.css';

function NavBar() {
    return(
        <div className='navContainer navContainerAbout'>
            <div className='logoContainer'>
                <img className='logo' src={Logo} alt="Logo oficial de Casual Entertainment." />
            </div>
            <div className='linksContainer'>
                <a className='link' href="/">Inicio</a>
                <a className='link'href="/about">Sobre nosotros</a>
                <a className='link'href="/contact">Contacto</a>
            </div>
        </div>
    )
}

export default NavBar;